import { createI18n } from 'vue-i18n'
import en from './en.json'
import id from './id.json'

const i18n = createI18n({
  locale: localStorage.getItem("activeLang") || 'id',
  messages: {
    id: id,
    en: en
  }
})

export default i18n