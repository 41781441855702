import { createApp,markRaw } from 'vue'
import App from './App.vue'
import router from './router';
import store from "./store";
import { createPinia } from 'pinia'
import { IonicVue } from '@ionic/vue';


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Tailwind CSS */
import './index.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* i18n */
import i18n from './locales/i18n'

import Copy from "vue3-copy";
import './registerServiceWorker'

const pinia = createPinia()
pinia.use(({ store }) => { store.router = markRaw(router) })

// Added by the CLI
import './registerServiceWorker';

const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(Copy)
router.isReady().then(() => {
  app.mount('#app');
});