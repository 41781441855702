<template>
 <ion-page>
     <main>
        <router-view></router-view>
    </main>
 </ion-page>
   

</template>
<script>
import { defineComponent } from 'vue'
import { IonPage } from '@ionic/vue';

export default defineComponent({
    components: { IonPage },

})
</script>

