import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from "../store";

import TabsLayout from '../layouts/TabsLayout.vue'
import AuthLayout from '../layouts/AuthLayout.vue';
import DetailLayout from '../layouts/DetailLayout.vue';

const routes = [
  {
    path: '/',
    redirect: '/tabs/tab1'
  },
  {
    path: '/auth',
    redirect: '/login',
    name: 'Auth',
    component: AuthLayout,
    meta: { isGuest: true },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/LoginPage.vue')

      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/auth/RegisterPage.vue')

      },
      {
        path: '/forgot_password',
        name: 'ForgotPassword',
        component: () => import('@/views/auth/ForgotPassword.vue')

      },
      {
        path: '/verification_phone',
        name: 'VerificationPhone',
        component: () => import('@/views/auth/VerificationPhone.vue')
      },
      {
        path: '/new_password',
        name: 'NewPassword',
        component: () => import('@/views/auth/NewPassword.vue')
      },
    ]
  },
  {
    path: '/detail',
    component: DetailLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/register_service',
        name: 'Layanan Pendaftaran Lab',
        component: () => import('@/views/RegisterService.vue')
      },
      {
        path: '/register_company_in_partner',
        name: 'Pendaftaran Lab Rekanan',
        component: () => import('@/views/RegisterCompanyInPartner.vue')
      },
      {
        path: '/register_lab',
        name: 'Pendaftaran Lab',
        component: () => import('@/views/RegisterLab.vue')
      },
      {
        path: '/info_payment_success/:is_tagihan',
        name: 'Info Pembayaran Berhasil',
        component: () => import('@/views/InfoPaymentSuccess.vue')
      },
      {
        path: '/payment_instruction/:id/:is_tagihan',
        name: 'Instruksi Pembayaran',
        component: () => import('@/views/PaymentInstruction.vue')
      },
      {
        path: '/payment_online_instruction/:id',
        name: 'Instruksi Pembayaran Online',
        component: () => import('@/views/PaymentOnlineInstruction.vue')
      },
      {
        path: '/payment_online_status',
        name: 'Status Pembayaran Online',
        component: () => import('@/views/PaymentOnlineStatus.vue')
      },
      {
        path: '/view_registration2/:id/:status',
        component: () => import('@/views/ViewRegistration2.vue'),
      },
      {
        path: '/update_profile',
        component: () => import('@/views/UpdateProfile.vue'),
      },
      {
        path: '/update_password',
        component: () => import('@/views/UpdatePassword.vue'),
      },
      {
        path: '/view_voucher/:id',
        name: 'ViewVoucher',
        component: () => import('@/views/ViewVoucher.vue')
      },
      {
        path: '/my_voucher/:id',
        name: 'ViewMyVoucher',
        component: () => import('@/views/ViewClaimVoucher.vue')
      }
    ]
  },
  {
    path: '/tabs/',
    component: TabsLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        name: 'ListRegistration',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'tab1/view_registration/:id',
        component: () => import('@/views/ViewRegistration.vue'),
      },
      {
        path: 'tab1/info_success/:id',
        name: 'Info Sukses Registrasi',
        component: () => import('@/views/InfoSuccess.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2Page.vue')
      },
      {
        path: 'tab3',
        name: 'ListEvent',
        component: () => import('@/views/Tab3Page.vue')
      },
      {
        path: 'tab3/view/:id',
        component: () => import('@/views/ViewJoinEvent.vue')
      },
      {
        path: 'tab4',
        name: 'ListPromotion',
        component: () => import('@/views/Tab4Page.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //cek nilai dari getters isLoggedIn di module auth
    if (store.getters['auth/isLoggedIn']) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
