import axios from "axios";

const Api = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}/api/v2`
})

// Api.interceptors.request.use(config => {
//     config.headers.Authorization = `Bearer ${store.state.user.token}`
//     return config;
// })

export default Api;